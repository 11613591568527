import {useEffect, useState} from 'react';
import ClientsClient from '../../api/clients/ClientsClient'

function useCaesiumCookie() {

  const [clients, setClients] = useState([])


  useEffect(() => {
    const getClients = async () => {
      const __clients = await ClientsClient.list()
      setClients(__clients)
    }

    getClients()
  }, [])

  return {clients}

}

export default useCaesiumCookie
