import styled from 'styled-components'

export const Colors = {
  // grays, whites and blacks
  gray: 'gray',
  lightGray: 'lightgray',
  slateGray: 'slategray',
  darkGray: '#505050',
  white: 'white',
  offWhite: 'ghostwhite',
  black: '#222222',
  // Accent colors
  blue: 'royalblue',
  lightBlue: 'skyblue',
  red: 'tomato',
  lightRed: 'lightcoral',
  green: 'green',
  orange: 'orange',
}

export const FlexBox = styled.div.attrs(props => ({
  align: props.align || 'stretch',
  justify: props.justify || 'center',
  direction: props.row ? 'row' : 'column'
}))`
  display: flex;
  align-items:     ${props => props.align};
  justify-content: ${props => props.justify};
  flex-direction:  ${props => props.direction};
`

export const FullScreenContainer = styled(FlexBox)`
  flex: 1;
  height: 100%;
`

export const GridBox = styled.div.attrs(props => ({
  align: props.align || 'stretch',
  justify: props.justify || 'strecth',
  cols: props.cols,
  rows: props.rows,
}))`
  display: grid;
  grid-template-columns: ${props => props.cols};
  grid-template-rows: ${props => props.rows};
  grid-column-gap: 0;
  grid-row-gap: 0;
  align-items: ${props => props.align};
  justify-items: ${props => props.justify};
`

export const GridItem = styled.div.attrs(props => ({
  colStart: props.colStart || 1,
  colEnd: props.colEnd || 2,
  rowStart: props.rowStart || 1,
  rowEnd: props.rowEnd || 2,
}))`
  grid-column-start: ${props => props.colStart};
  grid-column-end: ${props => props.colEnd};
  grid-row-start: ${props => props.rowStart};
  grid-row-end: ${props => props.rowEnd};
`
