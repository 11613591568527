/**
 * return a full timer. This is used to create a complete timer.
 * DO NOT use to START a timer.
 * NOE: we do not need to include the user here. That is done for us by the api based on the user running this operation.
 * @param  {number} duration time in Miliseconds
 * @return {Object}          the timer as an object ready to insert to the db.
 */
export const buildCompleteTimer = (timer) => {
  const __now = Date.now()

  const _default = {
    date: new Date(__now).toDateString(),
    description: '',
    started: false,
    finish: __now,
    start: __now,
  }

  return Object.assign({}, _default, timer)
}

export const getTimeElapsed = (timeStarted, formatted) => {
  formatted = formatted || false
  const duration = (Date.now() - timeStarted)
  return getTimeFromMS(duration, formatted)
}

//
export const getTimeFromMS = (duration, formatted) => {
  formatted = formatted || false

  const _secs = parseInt(duration / 1000)
  const _mins = parseInt(_secs / 60)
  const _hrs  = parseInt(_mins / 60)

  const hours = _hrs
  const minutes = (1 > _hrs)
  ? _mins
  : (_mins - (_hrs * 60))
  const seconds = (1 > _mins)
  ? _secs
  : (_secs - (_mins * 60))

  if (formatted) {
    return `${hours}:${minutes}:${seconds}`;
  }

  return {hours, minutes, seconds}
}

export const addTimersTotal = (documents, raw = false) => {
  let _count = 0;
  documents && documents.forEach((doc, i) => {
    if (doc.exists) {
      const _docData = doc.data()
      _count += _docData.duration || 0
    }
  });
  return (!!raw ? _count : getTimeFromMS(_count, true))
}

export const hours2MS = (hours) => {
  hours = hours || 0
  return 0 < hours ? (hours * 60 * 60 * 1000) : 0
}
