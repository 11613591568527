import React from 'react'
import NewProject from '../../components/NewProject'
import {
  H1,
  FullScreenContainer,
} from '../../styles'

// this component can check for params in the url
// and pass those down to the Reports component.
// for a persistent search
function AddProjectPage() {
  return (
    <FullScreenContainer justify={'flex-start'}>
      <H1>New Project</H1>
      <NewProject />
    </FullScreenContainer>
  )
}

export default AddProjectPage
