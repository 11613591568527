import styled from 'styled-components'

import {
  Colors,
  Button
} from '../../styles'

export const Instructions = styled.div`
  color: ${Colors.lightGray};
  font-weight: 100;
  padding: 2em;
  text-align: center;
`
export const BigButton = styled(Button)`
  padding: 4em 3em;
  background: linear-gradient(
    ${Colors.offWhite}, 85%,
    ${Colors.lightGray}
  );
  max-width: 80%;
  color: ${Colors.slateGray};
  font-size: 1.25em;
`
