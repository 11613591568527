import styled from 'styled-components'
import {
  Colors,
  FlexBox,
} from '../../../styles'

export const StatsContainer = styled(FlexBox)`
  padding: 1em 1em 3em 1em;

  @media screen and (min-width: 600px) {
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const ClientsFilter = styled(FlexBox)`
  width: 100%;
  padding: 1em;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2em;
`;

export const TopFilter = styled(FlexBox)`
  width: 100%;
  padding: 1em;
  padding-bottom: 0;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 0;
`;

export const Filter = styled(FlexBox)`
  padding: 0.5em 2em;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  margin-bottom: 0.5em;
  border-radius: 0.25em;
  cursor: pointer;
  color: ${props => props.active ? Colors.white : Colors.gray};
  background-color: ${props => props.active ? Colors.lightBlue : Colors.offWhite};
`;

export const ColorIdicator = styled(FlexBox)`
  flex-direction: row;
  alig-items: flex-start;
  justify-content: flex-start;
  margin: 0.25em 0;
  color: ${Colors.gray};
  &:before {
    content: '';
    height: 1em;
    width: 2em;
    margin-right: 1em;
    background-color: ${props => props.color
      ? Colors[props.color] : 'white'
    };
  }
`
