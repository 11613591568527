import React from 'react'
import Login from '../../pages/Login'
import App from '../App'
import WithUser from '../../helpers/WithUser'
import { BrowserRouter as Router } from "react-router-dom";

function AppPreloader() {

  return (
    <WithUser
      render={user => (<Router>
        {!user
          ? <Login />
          : <App user={user} />
        }
      </Router>)}
    />
  )
}

export default AppPreloader
