
import {useEffect, useState} from 'react';
import ProjectsClient from '../../api/clients/ProjectsClient'

function useProjects(options) {

  const [projects, setProjects] = useState([])

  useEffect(() => {
    const getProjects = async () => {
      const __projects = await ProjectsClient.list(options)

      setProjects(
        (options && options.excludeComplete) ?
        __projects.filter(_prj => (
          !_prj.data().complete || _prj.data().complete === false
        )) :
        __projects
      )
    }

    getProjects()
  }, [options])

  return {projects}

}

export default useProjects
