import React from 'react'
import UserClient from '../../../api/clients/UserClient'

import {
  FullScreenContainer,
  Button,
  FlexBox,
  H1
} from '../../styles'

function Login() {

  const _logIn = e => UserClient.logIn()

  return (
    <FullScreenContainer>
      <FlexBox align={'center'}>
        <H1 align={'center'}>
          Welcome To Caesium
        </H1>
        <Button
          onClick={_logIn}
        >
          Login with Google
        </Button>
      </FlexBox>
    </FullScreenContainer>
  )
}

export default Login
