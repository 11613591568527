import React, {useEffect, useState} from 'react'
import TimersClient from '../../../api/clients/TimersClient'
import NewTimer from '../../components/Timer/NewTimer'
import StopTimer from '../../components/Timer/StopTimer'
import {FullScreenContainer} from '../../styles'

function TimerPage() {
  // holds the current timer object, or null if no
  // current timer is active at the moment for curr user
  const [currentTimer, setCurrentTimer] = useState(null)

  useEffect(() => {
    // component is loaded
    let loaded = true
    // bind listener
    const listenToTimer = async () => {
      // when the db updates the currentTimer
      // this function gets called
      const next = snapshot => {
        setCurrentTimer(
          !snapshot.empty ? snapshot.docs[0] : null
        )
      }
      // checks for errors when db updates
      const error = err => {
        console.error(err)
      }
      // begin listenning
      // CurrentTimerClient.listenToCurrentTimer(next, error)
      TimersClient.listenToActiveTimer(next, error)
    }
    // call only if component is still loaded
    loaded && listenToTimer()
    // bail if component unmounts
    return () => loaded = false
  }, [])

  return (<FullScreenContainer>
    {currentTimer
      && currentTimer.exists
      && currentTimer.data().started
      ? <StopTimer timerSnap={currentTimer} />
      : <NewTimer />}
  </FullScreenContainer>)
}

export default TimerPage
