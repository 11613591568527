import styled from 'styled-components'

import {
  // Colors,
  FlexBox,
} from '../../styles'

export const ExpandableContainer = styled(FlexBox)`
  overflow: hidden;
`

export const ExpandableTitle = styled(FlexBox)`
  cursor: pointer;
  margin-bottom: 1em;
`

export const ExpandableContent = styled(FlexBox)`
  overflow: hidden;
  height: ${props => props.expand ?
    `auto` :
    '0em'
  }
`
