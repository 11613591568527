import React from 'react';
import { withRouter, Route, Switch } from "react-router-dom";
import TimerPage from '../../pages/Timer'
import ReportsPage from '../../pages/Reports'
import StatsPage from '../../pages/Stats'
import AddClientPage from '../../pages/AddClient'
import AddProjectPage from '../../pages/AddProject'
import UserAvatar from '../../components/UserAvatar'
import AppVersion from '../AppVersion'
import {
  LoggedInUser,
  PagesMenu,
  GoToPage,
  AppContainer,
  Header,
  Content,
  Footer,
  Logo
} from './App.styles'

function App(props) {
  const {
    user,
    location,
    history
  } = props

  return (<AppContainer>
    <Header>
      <PagesMenu>
        <Logo onClick={e => history.push('/')}>Cs</Logo>
        <GoToPage to={'/'} active={('/' === location.pathname) ? 'active' : undefined}>
        Timer
        </GoToPage>
        <GoToPage to={'/reports'} active={('/reports' === location.pathname) ? 'active' : undefined}>
        Reports
        </GoToPage>
        <GoToPage to={'/stats'} active={('/stats' === location.pathname) ? 'active' : undefined}>
        Stats
        </GoToPage>
      </PagesMenu>
    </Header>

    <Content>
      <Switch>
        <Route exact path="/" component={TimerPage} />
        <Route exact path="/reports" component={ReportsPage} />
        <Route exact path="/stats" component={StatsPage} />
        <Route exact path="/add-client" component={AddClientPage} />
        <Route exact path="/add-project" component={AddProjectPage} />
      </Switch>
    </Content>
    <Footer>
      <LoggedInUser>
        <AppVersion />
        <UserAvatar user={user} />
      </LoggedInUser>
    </Footer>
  </AppContainer>);
}

export default withRouter(App);
