import styled from 'styled-components'

import {
  Colors,
  FlexBox
} from '../../styles'

export const Avatar = styled(FlexBox)`
  right: 1em;
  border-radius: 50%;
  border: 0.01em solid ${Colors.blue};
  width: 2.5em;
  height: 2.5em;
  align-items: center;
  box-shadow: 0 0 0.5em 0 ${Colors.lightGray};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${props => props.photo || ''});
  cursor: pointer;
`
