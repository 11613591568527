import {useEffect, useState} from 'react';
import UserClient from '../../api/clients/UserClient'

function useLoggedInUser() {

  const [user, setUser] = useState()


  const listen = () => {
    const authObserver = _user => setUser(_user)

    const authError = error => console.error(error)
    UserClient.listener(authObserver, authError)
  }

  useEffect(() => {
    const getUser = async () => {
      const __user = await listen()
      setUser(__user)
    }
    undefined === user && getUser()
  }, [user])

  return {user}

}

export default useLoggedInUser
