import React, {useState} from 'react';
import ProjectsClient from '../../../api/clients/ProjectsClient'
import useClients from '../../hooks/useClients'
import {
  Form,
  Field,
  SelectField,
  SubmitBtn,
} from '../../styles'
import {
  FormContainer,
  Confirmation
} from './NewProject.styles'

import API from '../../../api'

function NewProject() {

  const {clients} = useClients()

  const [projectName, setProjectName] = useState('')
  const [estimate, setEstimate] = useState('')
  const [client, setClient] = useState('')
  const [confirmation, setConfirmation] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    if ('' === projectName) {
      alert('Project name cannot be empty.')
      return
    }

    if (0 === estimate) {
      alert('Estimate must be higher than 0.')
      return
    }

    const clientSelected = clients.find(_client => (client === _client.id))
    const clientData = clientSelected ? clientSelected.data() : {}
    const __clientToAdd = Object.assign({}, {id: client}, clientData)

    try {
      const newProjectToAdd = {
        name: projectName,
        estimate: +estimate,
        client: __clientToAdd
      }
      const projectCreated = await ProjectsClient.newProject(newProjectToAdd)
      if (projectCreated) {
        setProjectName('')
        setConfirmation('Project created successfully.')
        API.analytics.logEvent('New Project Added', {
          name: projectName,
          estimate: +estimate,
          client: __clientToAdd.name
        })
      }
    } catch (err) {
      console.error(err);
      setConfirmation(`The following error occurred: ${err.message}.`)
    }
  }

  const buildSelectOptions = docs => (docs &&
    docs.map(_doc => ({
      label: _doc.data().name,
      value: _doc.id
    }))
  )

  return (<>
    {'' !== confirmation &&
      <Confirmation>{confirmation}</Confirmation>}
    <FormContainer>
      <Form onSubmit={handleSubmit}>
        <label htmlFor={`projectName`}>
          {`Enter Project Name`}
          <Field
            value={projectName}
            onChange={e => setProjectName(e.target.value)}
            name={`projectName`}
          />
        </label>
        <label htmlFor={`estimate`}>
          {`Hours Estimate`}
          <Field
            value={estimate}
            type={'number'}
            onChange={e => setEstimate(+e.target.value)}
            name={`estimate`}
            placeholder={'125'}
          />
        </label>
        {clients &&
          <label field={'client'}>
            Clients
            <br />
            <SelectField
              name={'client'}
              value={client}
              onChange={e => setClient(e.target.value)}
              options={buildSelectOptions(clients)}
            />
          </label>
        }
        <SubmitBtn type={`submit`}>
          Save Project
        </SubmitBtn>
      </Form>
    </FormContainer>
  </>);
}

export default NewProject;
