import Firestore from '../firebase/clients/Firestore'

let clientsColl = null;

const init = () => {
  getClientsColl()
}

const getClientsColl = async () => {
  if (null === clientsColl) {
    clientsColl = await Firestore.getCollection(`Clients`)
  }
  return clientsColl
}

const get = async () => {
  try {
    // const user = await UserClient.getCurrentUser()
    const clients = await clientsColl.get()
    return clients
  } catch(err) {
    console.error(err)
  }
}

const list = async () => {
  try {
    // const user = await UserClient.getCurrentUser()
    const clients = await clientsColl.get()
    return clients.docs
  } catch(err) {
    console.error(err)
  }
}

const newClient = async (client) => {
  try {
    const __newClient = await clientsColl.add(client)
    return __newClient
  } catch(err) {
    console.error(err)
  }
}

const ClientsClient = {
  init,
  get,
  list,
  newClient
}

ClientsClient.init()

export default ClientsClient
