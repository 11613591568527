import React from 'react'
import ProjectsStats from './ProjectsStats'

function Stats() {

  return (<>
    <ProjectsStats />
  </>)
}

export default Stats
