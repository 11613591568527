import React, {useState} from 'react';
import {
  ExpandableContainer,
  ExpandableContent,
  ExpandableTitle
} from './Expandable.styles'

function Expandable({title, children}) {

  const [expand, setExpand] = useState(false)

  return (<ExpandableContainer >
    <ExpandableTitle onClick={e => setExpand(!!(!expand))}>
      {title}
    </ExpandableTitle>
    <ExpandableContent expand={expand}>
      {children}
    </ExpandableContent>

  </ExpandableContainer>);
}

export default Expandable;
