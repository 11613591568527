import React from 'react'
import styled from 'styled-components'
import {
  Colors,
} from './global'

export const Form = styled.form`
  width: 80%;
  max-width: 650px;

  label {
    display: block;
    letter-spacing: 0.075em;
    /* margin-bottom: 1em; */

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
`

export const Label = styled.label.attrs(props => ({
  htmlFor: props.field || props.htmlFor,
}))`
  font-size: 0.85em;
`

const fieldStyles = `
  background: ${Colors.white};
  border: 0;
  margin: 0.5em 0 1.5em;
  width: 100%;
  box-sizing: border-box;
  line-height: 1.5em;
  padding: 0.5em;
  border-radius: 0.25em;
  font-size: 100%;
  box-shadow: 0 0 0 0.025em ${Colors.lightGray};
  font-size: 100%;
`

export const Field = styled.input.attrs(props => ({
  type: props.type || 'text',
  id: props.name || '',
}))`
  ${fieldStyles}
  ${props => props.highlight
    && `
      color: ${Colors.slateGray};
      background-color: ${Colors.offWhite};
    `
  }
`

// build options for select field.
// requires React
const buildSelectOptions = (opts) => (
  [{
    label: 'Select Option',
    value: ''
  },
  ...opts].map((_opt, index) => (
    <option
      key={index}
      value={_opt.value || ''}
    >
      {_opt.label || 'no-label'}
    </option>
  ))
)

export const SelectField = styled.select.attrs(props => ({
  id: props.name || '',
  children: props.options
    ? buildSelectOptions(props.options)
    : props.children
}))`
  ${fieldStyles}
  appearance: none;
`

export const Button = styled.button.attrs(props => ({
  warning: props.warning,
}))`
  color: ${Colors.white};
  border: 0;
  letter-spacing: 0.125em;
  border-radius: 0.5em;
  padding: 0.8em 3em;
  box-shadow: 0 0 0 0.025em ${Colors.lightGray};
  background: linear-gradient(
    ${props => props.warning
      ? Colors.lightRed
      : Colors.lightBlue}, 30%,
    ${props => props.warning
      ? Colors.red
      : Colors.blue}
  );
`

export const SubmitBtn = styled(Button)`
  float: right;
`
