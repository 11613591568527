import styled from 'styled-components'
import {
  Colors,
  FlexBox,
} from '../../../../styles'

export const ProjectStat = styled(FlexBox)`
  position: relative;
  flex: 1;
  margin: 1em 0.5em;
  color: ${Colors.gray};
  padding: 1em;
  box-sizing: border-box;
  border-radius: 0.5em;
  border-style: solid;
  border-width: 0.25em 0.05em 0.05em 0.05em;
  /* comput color dynamically */
  border-color: ${props => 0 === props.perc
    ? Colors.gray
    : (65 > props.perc)
      ? Colors.green
      : (90 < props.perc)
        ? Colors.red
        : Colors.orange
  };
  min-width: 48%;
  max-width: 50em;

  @media screen and (min-width: 750px) {
    min-width: 32%;
  }
  @media screen and (min-width: 1400px) {
    min-width: 20%;
  }
`

export const ProjectTitle = styled(FlexBox)`
  font-size: 2em;
  color: ${Colors.gray};
  margin: 0.5em 0;
`

export const Estimate = styled(FlexBox)`
`

export const Actual = styled(FlexBox)`
  margin-top: 1em;
`

export const AdminButton = styled(FlexBox)`
  margin-top: 1em;
`

export const Percentage = styled(FlexBox)`
  align-items: center;
  font-size: 90%;
  margin-top: 1.5em;
  color: ${Colors.lightGray};
`

export const Stat = styled(FlexBox)`
  align-items: center;
  font-size: 3em;
  color: ${Colors.slateGray};
  padding: 0.25em;
`

export const StatName = styled(FlexBox)`
  align-items: center;
  font-size: 90%;
`

export const UseHours = styled(Percentage)`
  /* justify-content: center; */
`
