import React, {useState} from 'react'
import {StopTimerBtn} from './StopTimer.styles'
import TimerForm from '../TimerForm'
import {
  H5,
  FlexBox
} from '../../../styles'
import useInterval from '../../../hooks/useInterval'
import {getTimeElapsed} from '../../../helpers/timer'

function StopTimer({timerSnap}) {

  const timer = timerSnap.data();

  const [newTimer, setNewTimer] = useState(null)
  const [timeElapsed, setTimeElapsed] = useState(null)

  const __timerStarted = timer && new Date(timer.start)

  useInterval(() => {
    setTimeElapsed(getTimeElapsed(__timerStarted))
  }, 1000)

  const stopTimer = async e => {

    const __timer = {...timer}

    __timer.started = false
    __timer.finish  = Date.now()
    __timer.docID   = timerSnap.id

    const __timerEnded = new Date(__timer.finish)
    __timer.duration = (__timerEnded.getTime() - __timerStarted.getTime())

    setNewTimer(__timer)
  }

  if (newTimer) {
    return <TimerForm timer={newTimer} />
  }

  return (
    <FlexBox align={'center'}>
      <StopTimerBtn onClick={stopTimer}>
        Stop Timer
      </StopTimerBtn>

      <H5 align={'center'}>
      Timer Started<br />
      {timeElapsed
        ? `${timeElapsed.hours}:${timeElapsed.minutes}:${timeElapsed.seconds}`
        : '...'
      }
      </H5>
    </FlexBox>
  )
}

export default StopTimer
