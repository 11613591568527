import styled from 'styled-components'
import {
  Colors,
  FlexBox,
} from '../../../styles'

export const ReportsFormContainer = styled(FlexBox)`
  align-items: stretch;
  flex-direction: column;
  padding: 1em;
`;

export const OptionsWrapper = styled(FlexBox)`
  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
`

export const SearchByClient = styled(FlexBox)`
  flex: 1;
  padding: 0.25em;
`;

export const SearchByProject = styled(FlexBox)`
  flex: 1;
  padding: 0.25em;
`;

export const SearchByFreelancer = styled(FlexBox)`
  flex: 1;
  padding: 0.25em;
`;

export const SearchByDate = styled(FlexBox)`
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  label {
    flex: 1;
    padding: 0.25em;
  }
`;

export const Stats = styled(FlexBox)`
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 0.5em;
  border-top: 0.05em solid ${Colors.lightGray};
  border-bottom: 0.05em solid ${Colors.lightGray};
`;

export const Stat = styled(FlexBox)`
  span {
    color: ${Colors.darkGray};
    font-weight: 600;
    letter-spacing: 0.025em;
  }
`;
