import styled from 'styled-components'

import {
  Colors,
} from '../../../styles'
import {BigButton} from '../Timer.styles'

export const StopTimerBtn = styled(BigButton)`
  color: ${Colors.red};
`
