import React, {useState} from 'react'
import ReportsForm from './ReportsForm'
import TimersList from './TimersList'

function Reports() {

  const [timers, setTimers] = useState(null)

  const updateReports = (results) => {
    setTimers(results)
  }

  return (<>
    <ReportsForm
      onDocumentsUpdate={updateReports}
    />
    <TimersList timers={timers} />
  </>)
}

export default Reports
