import React, {useEffect, useState} from 'react'
import TimersClient from '../../../../api/clients/TimersClient'
import {Label, Field, SelectField} from '../../../styles'
import {addTimersTotal} from '../../../helpers/timer'
import {
  SearchByDate,
  SearchByClient,
  SearchByProject,
  SearchByFreelancer,
  ReportsFormContainer,
  Stats,
  Stat,
  OptionsWrapper,
} from './ReportsForm.styles'

import useClients from '../../../hooks/useClients'
import useProjects from '../../../hooks/useProjects'
import useUsers from '../../../hooks/useUsers'

function ReportsForm({onDocumentsUpdate}) {

  const {clients} = useClients()
  const {projects} = useProjects({excludeComplete: true})
  const {users} = useUsers()

  const [client, setClient] = useState('')
  const [project, setProject] = useState('')
  const [freelancer, setFreelancer] = useState('')
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')
  const [documents, setDocuments] = useState([])
  const [querySnapshot, setQuerySnapshot] = useState(null)

  // Query timers when filters change
  useEffect(() => {
    let __okToCall = true;
    // if filters are empty, bail
    if ( '' === client
      && '' === project
      && '' === freelancer
      && '' === dateFrom
      && '' === dateTo) {
      setDocuments([])
      return;
    }

    const queryTimers = async () => {
      const date = {
        from: ('' !== dateFrom) ? new Date(dateFrom).getTime() : null,
        to: ('' !== dateTo) ? new Date(dateTo).getTime() : null,
      }
      try {
        const _query = await TimersClient.query(
          client, project, freelancer, date
        )
        setDocuments(_query.docs)
        setQuerySnapshot(_query)
      } catch (err) {
        console.error(err);
      }
    }
    // query, if not unmounted
    __okToCall && queryTimers()
    // bail if unmounted
    return () => __okToCall = false
  }, [client, project, freelancer, dateFrom, dateTo])

  useEffect(() => {
    const alertListeners = () => {
      if (onDocumentsUpdate
      && 'function' === typeof onDocumentsUpdate) {
        onDocumentsUpdate(documents)
      }
    }

    querySnapshot && alertListeners()
  }, [documents, querySnapshot, onDocumentsUpdate])

  const buildSelectOptions = docs => (docs &&
    docs.map(_doc => ({
      label: _doc.data().name,
      value: _doc.id
    }))
  )

  const outputNumberDocs = () => (
    querySnapshot &&
      <span>{querySnapshot.size} timers found</span>
  )

  const addTimers = () => (
    querySnapshot
      && 0 < querySnapshot.size
      && <span>Total: {addTimersTotal(documents)}</span>
  )

  return (<ReportsFormContainer>
    <OptionsWrapper>
      {clients &&
        <SearchByClient>
          <Label field={'client'}>
            Clients
            <br />
            <SelectField
              name={'client'}
              value={client}
              onChange={e => setClient(e.target.value)}
              options={buildSelectOptions(clients)}
            />
          </Label>
        </SearchByClient>
      }

      {projects &&
        <SearchByProject>
          <Label field={'project'}>
            Projects
            <br />
            <SelectField
              name={'project'}
              value={project}
              onChange={e => setProject(e.target.value)}
              options={buildSelectOptions(projects)}
            />
          </Label>
        </SearchByProject>
      }
    </OptionsWrapper>
    <OptionsWrapper>
      {users &&
        <SearchByFreelancer>
          <Label field={'freelancer'}>
            Team Members
            <br />
            <SelectField
              name={'freelancer'}
              value={freelancer}
              onChange={e => setFreelancer(e.target.value)}
              options={buildSelectOptions(users)}
            />
          </Label>
        </SearchByFreelancer>
      }

      <SearchByDate>
        <Label field={'dateFrom'}>
          From:<br />
          <Field
            type={'date'}
            name={'dateFrom'}
            value={dateFrom}
            onChange={e => setDateFrom(e.target.value)}
          />
        </Label>
        <Label field={'dateTo'}>
          To:<br />
          <Field
            type={'date'}
            name={'dateTo'}
            value={dateTo}
            onChange={e => setDateTo(e.target.value)}
          />
        </Label>
      </SearchByDate>
    </OptionsWrapper>
    {querySnapshot &&
      0 < documents.length &&
      <Stats>
        <Stat>{outputNumberDocs()}</Stat>
        <Stat>{addTimers()}</Stat>
      </Stats>
    }
  </ReportsFormContainer>)
}

export default ReportsForm
