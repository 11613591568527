import styled from 'styled-components'
import { Link } from "react-router-dom";

import {
  Colors,
  FlexBox,
  GridBox,
  GridItem
} from '../../styles'

export const AppContainer = styled(GridBox)`
  height: 100vh;
  width: 100vw;
  grid-template-columns: 1fr;
  grid-template-rows: 3.5em auto 3.5em;
`
export const Header = styled(GridItem)`
  padding: 1em 1em 0.5em;
  box-sizing: border-box;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  border-bottom: 0.01em solid ${Colors.lightGray};
`

export const Content = styled(GridItem)`
  padding: 1em;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
`

export const Footer = styled(GridItem)`
  padding: 0.5em;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 3;
  border-top: 0.01em solid ${Colors.lightGray};
`

export const LoggedInUser = styled(FlexBox)`
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
`


export const PagesMenu = styled(FlexBox)`
  flex-direction: row;
  justify-content: space-around;
`

export const Logo = styled(FlexBox)`
  color: ${Colors.slateGray};
`

export const GoToPage = styled(Link)`
  color: ${Colors.blue};
  background: transparent;
  text-decoration: none;
  padding-bottom: 0.25em;
  border-bottom: ${props => props.active ? `0.1em` : 0} solid;
`
