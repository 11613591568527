import React from 'react'
import NewClient from '../../components/NewClient'
import {
  H1,
  FullScreenContainer,
} from '../../styles'

// this component can check for params in the url
// and pass those down to the Reports component.
// for a persistent search
function AddClientPage() {
  return (
    <FullScreenContainer justify={'flex-start'}>
      <H1>New Client</H1>
      <NewClient />
    </FullScreenContainer>
  )
}

export default AddClientPage
