import styled from 'styled-components'

import {
  FlexBox
} from '../../styles'

// const loadingIcon = require('../../../assets/loading/icon.gif')

export const LoadingIndicator = styled(FlexBox)`
  align-items: center;
`

export const loadingIconBaseSize = `5`

export const LoadingIcon = styled.img`
  max-width: ${props => props.size
    ? (loadingIconBaseSize * props.size)
    : loadingIconBaseSize}em;
`
