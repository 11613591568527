import Auth from '../firebase/clients/Auth'
import Firestore from '../firebase/clients/Firestore'

let _dbUsers = null;

const logIn = () => Auth.googleSignIn()

const listenToLogIn = () => Auth.listenToSigningIn()

const logOut = () => Auth.logOut()

const getCurrentUser = () => (Auth.getCurrentUser())

const getUsersColl = async () => {
  if (null === _dbUsers) {
    _dbUsers = await Firestore.getCollection(`Users`)
  }
  return _dbUsers
}

const isAdmin = async () => {
  try {
    const user = await UserClient.getCurrentUser()
    if (user && 'mario@vallgroup.com' === user.email) {
      return true;
    }
    return false;
  } catch(err) {
    console.error(err);
  }
}

const saveUser = async (newUser) => {
  try {
    const _users = await getUsersColl()
    const doc = await _users.add(newUser)
    return doc
  } catch(err) {
    console.error(err)
  }
}

const getUser = async (uid) => {
  try {
    const _users = await Firestore.getDoc(`Users/${uid}`)
    const __user = await _users.get()
    return __user
  } catch(err) {
    console.error(err)
  }
  return null;
}

const listener = (observer, onError) => {

  // const checkUser = async (user) => {
  //
  //   if (user) {
  //     observer(user)
  //     // try {
  //     //   const __user = await getUser(user.uid)
  //     //   observer(__user && __user.exists ? __user.data() : null)
  //     //   return;
  //     // } catch(err) {
  //     //   console.error(err)
  //     //   onError(err)
  //     // }
  //   }
  //   observer(null)
  // }

  Auth.stateListener(observer, onError)
}

const getFreelancers = async () => {
  try {
    const _users = await getUsersColl()
    const query = await _users.where('role', '==', 'freelancer')
    const qSnap = await query.get()
    return qSnap.docs
  } catch(err) {
    console.error(err)
  }
}

const getByRole = async (role) => {
  try {
    const _users = await getUsersColl()
    const query = await _users.where('role', '==', role)
    const qSnap = await query.get()
    return qSnap.docs
  } catch(err) {
    console.error(err)
  }
}

const getUsers = async (args = {}) => {
  try {
    const _users = await getUsersColl()
    // const query = await _users.where('role', '==', role)
    const qSnap = await _users.get()
    return qSnap.docs
  } catch(err) {
    console.error(err)
  }
}

const init = () => {}

const UserClient = {
  init,
  logIn,
  logOut,
  getCurrentUser,
  isAdmin,
  saveUser,
  getUser,
  listener,
  getFreelancers,
  listenToLogIn,
  getByRole,
  getUsers
}

UserClient.init()

export default UserClient
