import styled from 'styled-components'
import {
  Colors,
  FlexBox,
} from '../../../styles'
import trash from '../../../../assets/trash/trash.png'

export const TimersListContainer = styled(FlexBox)`
  padding: 1em 1em 3em 1em;
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
  color: ${Colors.gray};
`

const __field = `
  padding: 0.625em 0.5em;
  color: ${Colors.slateGray};
`

export const ListItem = styled.div`
  margin-bottom: 2em;
  border: 0;
  border-radius: 0.25em;
  box-shadow: 0 0 0 0.025em ${Colors.lightGray};
`

export const Time = styled(FlexBox)`
  ${__field}
  align-self: stretch;
  border: 0;
  border-radius: 0 0.25em 0 0;
  background-color: ${Colors.offWhite};
`

const __fieldWithLabel = `
  flex: 1;
  color: ${Colors.darkGray};
  span {
    color: ${Colors.slateGray};
    width: 3.75em;
  }
`

export const Project = styled.div`
  ${__field}
  ${__fieldWithLabel}
`

export const Client = styled.div`
  ${__field}
  ${__fieldWithLabel}
`

export const Description = styled(FlexBox)`
  ${__field}
  line-height: 1.4;
  font-family: monospace;
  color: ${Colors.darkGray};
`

export const Freelancer = styled.div`
  ${__field}
  flex: 1;

`

export const TimerDate = styled.div`
  ${__field}
  flex: 1;
  text-align: right;
  color: ${Colors.slateGray};
`

export const DeleteButton = styled.div`
  ${__field}
  text-align: right;
  color: ${Colors.slateGray};
  border-left: 0.1em solid ${Colors.lightGray};
  background-image: url(${trash});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  width: 2em;
  height: 1em;
  box-sizing: border-box;
  margin-top: 0.5em;
`

export const Critical = styled(FlexBox)`
  flex-direction: row;
  justify-content: space-between;
`

export const Billable = styled(FlexBox)`
  font-size: 90%;
  flex-direction: row;
  justify-content: space-between;
  border-top: 0.05em solid ${Colors.offWhite};
`

export const Meta = styled(FlexBox)`
  font-size: 90%;
  flex-direction: row;
  align-items: stretch;
  justify-content: cetner;
  border-top: 0.05em solid ${Colors.offWhite};
  border-radius: 0 0 0.25em 0.25em;
  background: ${Colors.offWhite};
`
