import React, {memo, useState} from 'react'
import useProjectsListener from '../../../hooks/useProjectsListener'
import useClients from '../../../hooks/useClients'
import Project from './Project'
// import {H1} from '../../../styles'
import {
  StatsContainer,
  ClientsFilter,
  Filter,
  TopFilter
} from './ProjectsStats.styles'

function ProjectsStats({stats}) {

  const {projects} = useProjectsListener()
  const {clients} = useClients()

  const [filterByClient, setFilterByClient] = useState('')
  const [showComplete, setShowComplete] = useState(false)

  return (<>
    <TopFilter>
      <Filter
        active={!!(showComplete)}
        onClick={e => setShowComplete(!showComplete)}
      >
        Show Complete
      </Filter>
    </TopFilter>
    <ClientsFilter>
      <Filter
        active={!!('' === filterByClient)}
        onClick={e => setFilterByClient('')}
      >
        All Clients
      </Filter>
      {clients
        && clients.map((cli) => (
          <Filter
            key={cli.id}
            active={!!(cli.id === filterByClient)}
            onClick={e => setFilterByClient(cli.id)}
          >
            {cli.data().name}
          </Filter>
        ))
      }
    </ClientsFilter>
    <StatsContainer>
      {projects
        && projects
        .filter((p, i) => {
          if ('' !== filterByClient) {
            return (filterByClient === p.data().client.id)
          }
          return true
        })
        .filter((p, i) => {
          if (showComplete) {
            return true
          }
          return (!p.data().complete)
        })
        .sort((a, b) => {
          const nameA = a.data().name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.data().name.toUpperCase(); // ignore upper and lowercase
          return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        })
        .map(prj => (
          <Project
            key={prj.id}
            project={prj}
          />
        ))
      }
    </StatsContainer>
  </>)
}

export default memo(ProjectsStats)
