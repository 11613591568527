import styled from 'styled-components'
import {BigButton} from '../Timer.styles'
import {
  Colors,
  FlexBox
} from '../../../styles'

export const StartTimer = styled(BigButton)`
  color: ${Colors.green};
`

export const Options = styled(FlexBox)`
  margin-top: 3em;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;

  a {
    margin: 0 1em;
    font-size: 90%;
    text-decoration: none;
    color: ${Colors.lightGray}
  }
`
