import React from 'react'
import Stats from '../../components/Stats'
import {
  H1,
  FullScreenContainer
} from '../../styles'
import {ColorIdicator} from '../../components/Stats/ProjectsStats/ProjectsStats.styles'

// this component can check for params in the url
// and pass those down to the Reports component.
// for a persistent search
function ReportsPage() {
  return (
    <FullScreenContainer justify={'flex-start'}>
      <H1>Stats</H1>
      <ColorIdicator color={'green'}>
        - Indicates Project is under 50% completed.
      </ColorIdicator>
      <ColorIdicator color={'orange'}>
        - Indicates Project is over 50% completed.
      </ColorIdicator>
      <ColorIdicator color={'red'}>
        - Indicates Project is over 90% completed.
      </ColorIdicator>
      <Stats />
    </FullScreenContainer>
  )
}

export default ReportsPage
