import styled from 'styled-components'
import {
  Colors,
  FlexBox,
} from '../../../styles'

export const FormContainer = styled(FlexBox)`
  align-items: center;
`

export const ExpandTitle = styled(FlexBox)`
  text-align: right;
  font-size: 90%;
  color: ${Colors.lightGray};
`
