import * as firebase from 'firebase'
import config from './firebase/config'

import 'firebase/analytics'

let analytics = null;

function init() {
  this.firebase && this.firebase.initializeApp(config)
  this.analytics = this.firebase.analytics()
}

const API = {
  firebase,
  init,
  analytics
}

API.init()

export default API
