import React from 'react'
import {Link} from 'react-router-dom'
import TimersClient from '../../../../api/clients/TimersClient'
import {FlexBox} from '../../../styles'
import {
  StartTimer,
  Options
} from './NewTimer.styles'

import API from '../../../../api'

function NewTimer() {

  const startTimer = async e => {
    // build timer
    const newTimer = {
      started: true,
      start: Date.now(),
    }
    try {
      // save timer
      await TimersClient.saveTimer(newTimer)
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <FlexBox align={'center'}>
      <StartTimer onClick={startTimer}>
        Start Timer
      </StartTimer>
      <Options>
        <Link
          to={'/add-client'}
          onClick={() => {
            API.analytics.logEvent('New Client', {action: 'click'})
          }}
        >
          New Client
        </Link>
        <Link
          to={'/add-project'}
          onClick={() => {
            API.analytics.logEvent('New Project', {action: 'click'})
          }}
        >
          New Project
        </Link>
      </Options>
    </FlexBox>
  )
}

export default NewTimer
