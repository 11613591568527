import styled from 'styled-components'
import {Colors} from './global'

const parseHeaderProps = props => ({
  color: Colors[props.colos] || Colors.slateGray,
  align: props.align || 'inherit',
})

export const H1 = styled.h3.attrs(parseHeaderProps)`
  font-size: 3em;
  letter-spacing: 0.05em;
  margin: 0.25em 0 1em;
  color: ${props => props.color};
  font-weight: 300;
  text-align: ${props => props.align};
  line-height: 1.4em;
`
export const H2 = styled.h3.attrs(parseHeaderProps)`
  font-size: 2.5em;
  letter-spacing: 0.05em;
  margin: 1em 0 2em;
  color: ${props => props.color};
  font-weight: 300;
  text-align: ${props => props.align};
  line-height: 1.4em;
`
export const H3 = styled.h3.attrs(parseHeaderProps)`
  font-size: 2em;
  letter-spacing: 0.05em;
  margin: 1em 0 2em;
  color: ${props => props.color};
  font-weight: 300;
  text-align: ${props => props.align};
  line-height: 1.4em;
`
export const H4 = styled.h3.attrs(parseHeaderProps)`
  font-size: 1.5em;
  letter-spacing: 0.05em;
  margin: 1em 0 2em;
  color: ${props => props.color};
  font-weight: 400;
  text-align: ${props => props.align};
  line-height: 1.4em;
  text-transform: uppercase;
`
export const H5 = styled.h3.attrs(parseHeaderProps)`
  font-size: 1.25em;
  letter-spacing: 0.05em;
  margin: 1em 0 2em;
  color: ${props => props.color};
  font-weight: 400;
  text-align: ${props => props.align};
  line-height: 1.4em;
`
export const H6 = styled.h3.attrs(parseHeaderProps)`
  font-size: 1em;
  letter-spacing: 0.05em;
  margin: 1em 0 2em;
  color: ${props => props.color};
  font-weight: 700;
  text-align: ${props => props.align};
  line-height: 1.4em;
  text-transform: uppercase;
`

// TODO: remove this from here. it should be reconciled with the Button under form.js.
export const Anchor = styled.a.attrs(props => ({
  href: props.href || '#'
}))`
  color: ${props => props.color || Colors.blue};
  text-decoration: none;
  cursor: pointer;
`
