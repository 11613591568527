import React, {useState} from 'react';
import ClientsClient from '../../../api/clients/ClientsClient'
import {
  Form,
  Field,
  SubmitBtn,
} from '../../styles'
import {
  FormContainer,
  Confirmation
} from './NewClient.styles'

import API from '../../../api'

function NewClient() {

  const [clientName, setClientName] = useState('')
  const [confirmation, setConfirmation] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    if ('' === clientName) {
      alert('Client name cannot be empty.')
      return
    }

    try {
      const clientCreated = await ClientsClient.newClient({name: clientName})
      if (clientCreated) {
        setClientName('')
        setConfirmation('Client created successfully.')
        API.analytics.logEvent('New Client Added', {
          name: clientName
        })
      }
    } catch (err) {
      console.error(err);
      setConfirmation(`The following error occurred: ${err.message}.`)
    }
  }

  return (<>
    {'' !== confirmation &&
      <Confirmation>{confirmation}</Confirmation>}
    <FormContainer>
      <Form onSubmit={handleSubmit}>
        <label htmlFor={`clientName`}>
          {`Enter Client Name`}
          <Field
            value={clientName}
            onChange={e => setClientName(e.target.value)}
            name={`clientName`}
          />
        </label>
        <SubmitBtn type={`submit`}>
          Save Client
        </SubmitBtn>
      </Form>
    </FormContainer>
  </>);
}

export default NewClient;
