import React, {memo} from 'react'
import TimersListItem from './TimersListItem'
import {TimersListContainer} from './TimersList.styles'

const NO_RESULTS_MSG = 'No timers were found.'
const INITIAL_LOADING_MSG = 'Begin searching for timers by selecting options above.'

function TimersList({timers}) {

  return (
    <TimersListContainer>
      {null === timers
        ? <>{INITIAL_LOADING_MSG}</>
        : (0 < timers.length)
          ? timers.map((_timer, _idx) => (
            <TimersListItem
              key={_idx}
              timer={_timer}
            />
          ))
          : <>{NO_RESULTS_MSG}</>
      }
    </TimersListContainer>
  )
}

export default memo(TimersList)
