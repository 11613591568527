import styled from 'styled-components'
import {
  FlexBox
} from '../../styles'

export const FormContainer = styled(FlexBox)`
  align-items: center;
`
export const Confirmation = styled(FlexBox)`
  align-items: center;
  padding: 0 0 2em 0;
`
