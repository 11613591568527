import Firestore from '../firebase/clients/Firestore'
import UserClient from '../clients/UserClient'
import API from '../'

import {getTimeFromMS} from '../../lib/helpers/timer'

let timersColl = null;

const init = () => {}

const getTimersColl = async () => {
  if (null === timersColl) {
    timersColl = await Firestore.getCollection(`Timers`)
  }
  return timersColl
}


const saveTimer = async (newTimer) => {
  try {
    const user = await UserClient.getCurrentUser()
    const userDoc = await UserClient.getUser(user.uid)
    if (userDoc && userDoc.exists) {
      // log that the user started a new timer
      API.analytics.logEvent('timer_started', {
        uid: user.uid,
        start: newTimer.start
      });
      const timers = await getTimersColl()
      const doc = await timers.add({...newTimer,
        user: userDoc.data()
      })
      return doc
    }
  } catch(err) {
    console.error(err)
  }
}

const getTimersForCurrentUser = async () => {
  try {
    const user = await UserClient.getCurrentUser()
    const timers = await getTimersColl()
    const query = await timers.where('user', '==', user.uid)
    const docs = await query.get()
    return docs.docs
  } catch(err) {
    console.error(err)
  }
}

const getActiveTimerQuery = async () => {
  try {
    const user   = await UserClient.getCurrentUser()
    const timers = await getTimersColl()
    const query  = await timers
      .where('user.id', '==', user.uid)
      .where('started', '==', true)
    return query;
  } catch(err) {
    console.error(err)
  }
}

const getActiveTimer = async () => {
  try {
    const query = await getActiveTimerQuery()
    const qSnap = await query.get()
    return !qSnap.empty ? qSnap.docs[0] : null;
  } catch(err) {
    console.error(err)
  }
}

const listenToActiveTimer = async (onNext, onError) => {
  try {
    const query = await getActiveTimerQuery()
    query.onSnapshot(onNext, onError)
  } catch(err) {
    console.error(err)
  }
}

const saveActiveTimer = async (newTimer) => {
  try {
    const timreDoc = await Firestore.getDoc(`Timers/${newTimer.docID}`)
    const updateDoc = await timreDoc.update(newTimer)
    // console.log(newTimer);
    // log that this user stopped a timer
    const user = await UserClient.getCurrentUser()
    API.analytics.logEvent('timer_stopped', {
      uid: user.uid,
      start: newTimer.start,
      finish: newTimer.finish,
      duration: newTimer.duration || 0
    });
    API.analytics.logEvent('time_logged', {
      uid: user.uid,
      time: newTimer.time,
      duration: newTimer.duration || 0,
      client: (newTimer.client && newTimer.client.name) || 'no-client',
      project: (newTimer.project && newTimer.project.name) || 'no-project',
      by: newTimer.user && newTimer.user.name,
      role: newTimer.user && newTimer.user.role,
      ...getTimeFromMS(newTimer.duration)
    });
    return updateDoc
  } catch(err) {
    console.error(err)
  }
}

// return a QuerySnapshot
// see : https://firebase.google.com/docs/reference/js/firebase.firestore.QuerySnapshot?authuser=0
const query = async (client, project, freelancer, date) => {
  API.analytics.logEvent('query_performed', {client, project, freelancer, date});
  try {
    const _operator = val => (
      ('' !== val) ? '==' : '>'
    )

    let _query = await getTimersColl()

    if ('' !== client) {
      _query = await _query
        .where(
          'client.id',
          _operator(client),
          client
        )
    }

    if ('' !== project) {
      _query = await _query
      .where(
        'project.id',
        _operator(project),
        project
      )
    }

    if ('' !== freelancer) {
      _query = await _query
      .where(
        'user.id',
        _operator(freelancer),
        freelancer
      )
    }

    if (date && date.from) {
      _query = await _query
      .where(
        'finish',
        '>',
        date.from
      )
    }

    if (date && date.to) {
      _query = await _query
      .where(
        'finish',
        '<',
        date.to
      )
    }

    // return the query resutls
    const _docs = await _query.get()
    return _docs;
  } catch (err) {
    console.error(err);
    console.trace(query)
  }
}

const getTimersByProject = async (projectID) => {
  try {
    let _query = await getTimersColl()

    _query = await _query
    .where(
      'project.id',
      '==',
      projectID
    )

    // return the query resutls
    const _docs = await _query.get()
    return _docs;
  } catch (err) {
    console.error(err);
    console.trace(query)
  }
}

const getById = async (timerID) => {
  try {
    let _query = await getTimersColl()
    return _query.doc(timerID)
  } catch (err) {
    console.error(err);
    console.trace(query)
  }
}

const TimersClient = {
  init,
  timersColl,
  getById,
  getTimersColl,
  saveTimer,
  getTimersForCurrentUser,
  query,
  getActiveTimer,
  listenToActiveTimer,
  saveActiveTimer,
  getTimersByProject
}

TimersClient.init()

export default TimersClient
