import API from '../..'

import 'firebase/firestore'

let firestore = null

const init = () => {
  firestore = API.firebase.firestore()
}

const getDoc = path => {
  return firestore.doc(path)
}

const getCollection = path => {
  return firestore.collection(path)
}

const Firestore = {
  init,
  getDoc,
  getCollection
}

Firestore.init()

export default Firestore
