import {useEffect, useState} from 'react';
import UserClient from '../../api/clients/UserClient'

function useUsers() {

  const [users, setUsers] = useState([])


  useEffect(() => {
    const getUsers = async () => {
      const __users = await UserClient.getUsers()
      setUsers(__users)
    }

    getUsers()
  }, [])

  return {users}

}

export default useUsers
