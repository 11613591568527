import React, {useState, useEffect} from 'react'
import TimersClient from '../../../../../api/clients/TimersClient'
import {
  getTimeFromMS,
  addTimersTotal,
  buildCompleteTimer,
  hours2MS
} from '../../../../helpers/timer'
import {
  Actual,
  Stat,
  StatName,
  Percentage,
  UseHours
} from './Project.styles'
import {
  Anchor,
  Colors
} from '../../../../styles'

import Loading from '../../../Loading'

import API from '../../../../../api'

function ProjectProgress({project, onPercentUpdate}) {

  const [refresh, setRefresh] = useState(false)
  const [timersTotoal, setTimersTotoal] = useState(null)
  const [percentage, setPercentage] = useState(0)


  const projectData = project && project.data()
  const estimateMS = hours2MS(projectData.estimate)

  // get project timers
  useEffect(() => {
    let __okToCall = true;
    const loadTimers = async () => {
      try {
        const _query = await TimersClient.getTimersByProject(project.id)
        // const __docs = _query.docChanges().map(doc => (doc.doc))
        setTimersTotoal(addTimersTotal(_query.docs, true))
      } catch (err) {
        console.error(err);
      }
    }

    project.id && __okToCall && loadTimers();

    return () => {__okToCall = false;}
  }, [refresh, project.id])

  useEffect(() => {
    setPercentage((
      0 < projectData.estimate ?
      ((timersTotoal / estimateMS) * 100).toFixed(0) :
      0
    ))
  }, [timersTotoal, estimateMS, projectData.estimate])

  useEffect(() => (onPercentUpdate(percentage)), [percentage, onPercentUpdate])

  const useAllHours = (e) => {
    e.preventDefault();
    setTimersTotoal(null)
    // if there are hours left
    if (estimateMS > timersTotoal) {
      const _duration = (estimateMS - timersTotoal)
      const _newTimer = buildCompleteTimer({
        description: 'Gap Timer',
        duration: _duration,
        time: getTimeFromMS(_duration, true),
        client: projectData.client,
        project: {
          estimate: projectData.estimate,
          id: project.id,
          name: projectData.name,
        }
      })
      _saveGapTimer(_newTimer)
    }
  }

  // saves a gap timer to remaining hours
  const _saveGapTimer = async (gapTimer) => {
    try {
      await TimersClient.saveTimer(gapTimer)
      API.analytics.logEvent('Gap Timer', {
        project: gapTimer.name,
        duration: gapTimer.duration,
        time: gapTimer.time,
        client: gapTimer.client && gapTimer.client.name,
      })
      setRefresh(!refresh)
    } catch(err) {
      console.error(err);
    }
  }

  const hoursLeft = () => (estimateMS > timersTotoal)

  // show loading icon while getting timers
  if (null === timersTotoal) {
    return (<>
      <Loading size={1.5} />
    </>)
  }

  return (<>
    <Actual>
      <Stat>{getTimeFromMS(timersTotoal, true)}</Stat>
      <StatName>Total Hours</StatName>
    </Actual>
    <Percentage>
      {percentage}% complete
    </Percentage>
    {hoursLeft() &&
      <UseHours>
        <Anchor onClick={useAllHours} color={Colors.gray}>
          Add gap timer to log remaining hours
        </Anchor>
      </UseHours>
    }
  </>)
}

export default ProjectProgress
