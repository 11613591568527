import React, {useState, useEffect} from 'react'
import UserClient from '../../../../api/clients/UserClient'
import TimersClient from '../../../../api/clients/TimersClient'
import {
  ListItem,
  Time,
  Project,
  Client,
  Description,
  Freelancer,
  TimerDate,
  Critical,
  Billable,
  Meta,
  DeleteButton
} from './TimersList.styles'

function TimersListItem({timer}) {

  // Project: {timer.project}
  // Client: {timer.client}
  // Date: {timer.date}
  // Description: {timer.description}
  // Start: {timer.start}
  // Finish: {timer.finish}
  // Time: {timer.time}
  // User: {timer.user}

  const [timerObj, setTimerObj] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    setTimerObj(timer.data())
  }, [timer])

  useEffect(() => {
    const getUser = async () => {
      try {
        const admin = await UserClient.isAdmin()
        setIsAdmin(admin)
      } catch(err) {
        console.error(err);
      }
    }
    getUser()
  }, [])

  const handleDelete = async (e) => {
    e.preventDefault();

    const _confirmation = window.confirm('Are you sure you want to delete this timer?')

    if (!_confirmation) return;

    try {
      // we must retrieve the doc first because what we
      // have in 'timer' is a QueryDocumentSnapshot, not
      // an actual document.
      const __timerDoc = await TimersClient.getById(timer.id)
      __timerDoc.delete();
      setTimerObj(null)
    } catch(err) {
      console.error(err);
    }
    console.log(timer);
  }

  if (!timerObj) return null;

  const ___project = timerObj.project && timerObj.project.name
  const ___client = timerObj.client && timerObj.client.name

  return (timerObj &&
    <ListItem>
      <Critical>
        <Description>
          {timerObj.started && !timerObj.duration
            ? `Timer in progress...`
            : (timerObj.description || '--')}
        </Description>
        <Time>{timerObj.time || `0:0:0`}</Time>
      </Critical>

      <Billable>
        {___project &&
          <Project>
            <span>Project:</span> {___project}
          </Project>
        }
        {___client &&
          <Client>
            <span>Client:</span> {___client}
          </Client>
        }
      </Billable>

      <Meta>
        {timerObj && timerObj.user &&
          <Freelancer>
            {timerObj.user.name || 'unassigned'}
          </Freelancer>
        }
        {timerObj && timerObj.date &&
          <TimerDate>{timerObj.date}</TimerDate>
        }
        {isAdmin &&
          <DeleteButton onClick={handleDelete} />
        }
      </Meta>
    </ListItem>
  )
}

export default TimersListItem
