import React from 'react'
import Reports from '../../components/Reports'
import {
  H1,
  FullScreenContainer
} from '../../styles'

// this component can check for params in the url
// and pass those down to the Reports component.
// for a persistent search
function ReportsPage() {
  return (
    <FullScreenContainer justify={'flex-start'}>
      <H1>Reports</H1>
      <Reports />
    </FullScreenContainer>
  )
}

export default ReportsPage
