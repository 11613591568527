import React from 'react'
// import Auth from '../../api/firebase/clients/Auth'
// import UserClient from '../../api/clients/UserClient'
import Loading from '../components/Loading'
import useLoggedInUser from '../hooks/useLoggedInUser'
import {FullScreenContainer} from '../styles'

function WithUser(props) {
  // store user
  const {user} = useLoggedInUser()
  // the prop render is required
  if (!props.render
    || 'function' !== typeof props.render) {
    console.warn(`WithUser: prop 'render' must be a function. ${typeof props.render} was given.`)
    return null;
  }
  // once the session has began, the user will either be
  // null or an object. So if it is false it means we are
  // still loading the session.
  if (false === user) {
    return (
      <FullScreenContainer align={'center'}>
        <Loading size={3} />
      </FullScreenContainer>
    )
  }
  // render with user
  return props.render(user)
}

export default WithUser
