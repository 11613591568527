import React, {useEffect, useState} from 'react'
import TimersClient from '../../../../api/clients/TimersClient'
import Expandable from '../../Expandable'
import {
  FormContainer,
  ExpandTitle
} from './TimerForm.styles'
import {
  Form,
  Field,
  SelectField,
  SubmitBtn,
} from '../../../styles'


import {getTimeFromMS} from '../../../helpers/timer'

import useClients from '../../../hooks/useClients'
import useProjects from '../../../hooks/useProjects'

const __todaysDate = new Date(Date.now()).toDateString()

function TimerForm({timer}) {

  const {clients} = useClients()
  const {projects} = useProjects({excludeComplete: true})

  const [date, setDate] = useState(__todaysDate)
  const [time, setTime] = useState('')
  const [description, setDescription] = useState('')
  const [client, setClient] = useState('')
  const [project, setProject] = useState('')

  const [duration, setDuration] = useState(timer.duration)

  useEffect(() => {
    const calculateTime = () => {
      setTime(getTimeFromMS(duration, true))
    }

    timer
      && duration
      && calculateTime()
  }, [timer, duration])

  const buildSelectOptions = (docs) => {
    return docs.map((_doc, index) => (
      <option
        key={index}
        value={_doc.id}
      >
        {_doc.data().name}
      </option>
    ))
  }

  const objIDLookUp = (objID, arr) => {
    const __obj = arr.find(obj => (
      obj.id === objID
    ))

    if (__obj && __obj.exists) {
      const __fullObj = {...__obj.data()}
      __fullObj.id = objID
      return __fullObj;
    }
    return __obj
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const __client  = objIDLookUp(client, clients)
    const __project = objIDLookUp(project, projects)

    const __timer = {...timer,
      date,
      time,
      description,
      client: __client ? __client : null,
      project: __project ? __project : null,
    }
    __timer.duration = +duration;
    await TimersClient.saveActiveTimer(__timer)
  }

  const handleProjectSelect = ({ target }) => {
    const _projectId = target.value
    const _projectFound = projects.find(project => project.id === _projectId)

    if (_projectFound ) {
      //_projectFound found, update client state with project's client id
      setClient(_projectFound.data().client.id)
    }
    setProject(target.value)
  }

  return (
    <FormContainer>
      {/* Display A title ? */}
      <Form onSubmit={handleSubmit}>
        <label htmlFor={`date`}>
          {`Date`}
          <Field
            value={date}
            onChange={e => setDate(e.target.value)}
            name={`date`}
          />
        </label>
        <label htmlFor={`time`}>
          {`Time Recorded`}
          <Field
            value={time}
            highlight={true}
            onChange={e => e.preventDefault()}
            name={`time`}
          />
        </label>
        <Expandable title={
          <ExpandTitle>{`Adjust Time`}</ExpandTitle>
        }>
          <Field
            value={duration}
            type={'range'}
            onChange={e => setDuration(e.target.value)}
            name={`duration`}
            min={0}
            max={timer.duration + (24 * 3600000)}
          />
        </Expandable>
        <label htmlFor={`description`}>
          {`Description`}
          <Field
            value={description}
            onChange={e => setDescription(e.target.value)}
            name={`description`}
          />
        </label>
        <label htmlFor={`project`}>
          {`Project`}
          {projects
            && <SelectField
              value={project}
              onChange={handleProjectSelect}
              name={`project`}
            >
              <option value={''}>Select a project</option>
              {buildSelectOptions(projects)}
            </SelectField>
          }
        </label>
        <label htmlFor={`client`}>
          {`Client`}
          {clients
            && <SelectField
              value={client}
              onChange={e => setClient(e.target.value)}
              name={`client`}
            >
              <option value={''}>Select a client</option>
              {buildSelectOptions(clients)}
            </SelectField>
          }
        </label>
        <SubmitBtn type={`submit`}>
          Save Timer
        </SubmitBtn>
      </Form>
    </FormContainer>
  )
}

export default TimerForm
