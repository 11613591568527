import Firestore from '../firebase/clients/Firestore'

let projectsColl = null;

const init = () => {
  getProjectsColl()
}

const getProjectsColl = async () => {
  if (null === projectsColl) {
    projectsColl = await Firestore.getCollection(`Projects`)
  }
  return projectsColl
}

const get = async () => {
  try {
    // const user = await UserClient.getCurrentUser()
    const projects = await projectsColl.get()
    return projects
  } catch(err) {
    console.error(err)
  }
}

const update = async (pid, newProject) => {
  try {
    // const user = await UserClient.getCurrentUser()
    const project = await projectsColl.doc(`/${pid}`)
    const update = await project.update(newProject)
    return update
  } catch(err) {
    console.error(err)
  }
}

const list = async () => {
  try {
    // const user = await UserClient.getCurrentUser()
    const projects = await projectsColl.get()
    return projects.docs
  } catch(err) {
    console.error(err)
  }
}

const listen = async (onNext, onError, onCompletion) => projectsColl.onSnapshot(onNext, onError, onCompletion)

const newProject = async (project) => {
  try {
    const __newPrj = await projectsColl.add(project)
    return __newPrj
  } catch(err) {
    console.error(err)
  }
}

const ProjectsClient = {
  init,
  get,
  list,
  newProject,
  update,
  listen
}

ProjectsClient.init()

export default ProjectsClient
