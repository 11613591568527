import React from 'react'
import {
  LoadingIndicator,
  LoadingIcon
} from './Loading.styles'
import loadingIcon from '../../../assets/loading/icon.gif'

function Loading({size}) {

  return (
    <LoadingIndicator>
      <LoadingIcon
        size={size || 1}
        src={loadingIcon}
      />
    </LoadingIndicator>
  );
}

export default Loading;
