/**
 * STAGING CONFIG
 */
// const config = {
//   apiKey: "AIzaSyCVUWDQ4ZmzUt_b_g9NQLSGH6AjfHQbRwA",
//   authDomain: "staging-ceasium.firebaseapp.com",
//   databaseURL: "https://staging-ceasium.firebaseio.com",
//   projectId: "staging-ceasium",
//   storageBucket: "staging-ceasium.appspot.com",
//   messagingSenderId: "37675008463",
//   appId: "1:37675008463:web:632bfce84f6da59f0e92ba",
//   measurementId: "G-3SFEJN714L"
// };

/**
 * PRODUCTION CONFIG
 */
const config = {
  apiKey: "AIzaSyAm2lI_MI7PJZz_Jp_v9D-GFNkrGoil6AY",
  authDomain: "caesium-b6be4.firebaseapp.com",
  databaseURL: "https://caesium-b6be4.firebaseio.com",
  projectId: "caesium-b6be4",
  storageBucket: "caesium-b6be4.appspot.com",
  messagingSenderId: "108594648191",
  appId: "1:108594648191:web:70f621094729cb3c869b4a",
  measurementId: "G-JB8VBPP9TK"
};

export default config
