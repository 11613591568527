import React from 'react'
import UserClient from '../../../api/clients/UserClient'
import {
  Avatar,
} from './UserAvatar.styles'

function UserAvatar({user}) {

  return (user && <Avatar
    photo={user.photoURL}
    onClick={e => UserClient.logOut()}
  />);
}

export default UserAvatar;
