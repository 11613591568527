import {useEffect, useState} from 'react';
import ProjectsClient from '../../api/clients/ProjectsClient'

// Listens to projects collection
// and refreshes in real time
function useProjectsListener() {

  const [projects, setProjects] = useState([])


  useEffect(() => {
    const getProjects = async () => {
      ProjectsClient.listen(
        // onNext
        (snapshot) => setProjects(snapshot.docs),
        // onError
        (error) => console.error(error),
        // onCompletion
        (snapshot) => setProjects(snapshot.docs)
      )
    }

    getProjects()
  }, [])

  return {projects}

}

export default useProjectsListener
