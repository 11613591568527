import React, {useState} from 'react'
import ProjectsClient from '../../../../../api/clients/ProjectsClient'
import {
  ProjectStat,
  ProjectTitle,
  Estimate,
  Stat,
  StatName,
  AdminButton,
} from './Project.styles'
import {
  Button,
} from '../../../../styles'

import ProjectProgress from './ProjectProgress'

import API from '../../../../../api'

function Project({project}) {

  const [percentCompleted, setPercentCompleted] = useState(0)

  const __p = project && project.data()

  const markComplete = () => _markPrjComplete(true)

  const markInComplete = () => _markPrjComplete(false)

  // marks project as complete.
  const _markPrjComplete = async (isComplete = true) => {
    try {
      await ProjectsClient.update(project.id, {
        complete: isComplete
      })
      if (isComplete) {
        API.analytics.logEvent('Project Completed', {
          name: __p.name,
          estimate: __p.estimate,
          client: __p.client && __p.client.name
        })
      }
    } catch(err) {
      console.error(err);
    }
  }

  if (!__p) {
    return null;
  }

  return (<ProjectStat perc={percentCompleted}>
    <ProjectTitle>{__p.name}</ProjectTitle>
    <Estimate>
      <Stat>{__p.estimate}</Stat>
      <StatName>Estimated Hours</StatName>
    </Estimate>
    <ProjectProgress
      project={project}
      onPercentUpdate={(percent) => setPercentCompleted(percent)}
    />
    {(__p.complete) ?
      <AdminButton>
        <Button onClick={markInComplete}>
          Mark In Progress
        </Button>
      </AdminButton> :
      <AdminButton>
        <Button warning onClick={markComplete}>
          Mark Complete
        </Button>
      </AdminButton>
    }
  </ProjectStat>)
}

export default Project
